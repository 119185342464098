<template>
  <div>
    <b-row>
      <b-col cols="12" class="text-center mb-1 d-flex justify-content-end">
        <b-button variant="primary" to="/rawmaterials/add">
          <span class="text-nowrap">Add Raw Material</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="3">
        <b-form-group label="Raw Material Name" label-for="h-Order-name">
          <b-form-select
            v-model="selectedName"
            :options="productName"
            @change="orderFilterChanged()"
          >
            <option value="">All</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Category" label-for="h-Order-code">
          <b-form-select
            v-model="selectedCategory"
            :options="categoryName"
            @change="orderFilterChanged()"
          >
            <option value="">All</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col col="3" class="d-flex align-items-center">
        <b-button @click="clearFilters"> Clear </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>

      <b-col md="10" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="8"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to filter by Description"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="RawMaterialListing"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <!-- <template #cell(isActive)="data">
            <b-badge v-if="data.item.isActive" variant="primary"> Active </b-badge>
            <b-badge v-else variant="danger"> Inactive </b-badge>
          </template> -->
          <template #cell(balance)="data">
            <b-button :to="getUpdate(data.item.id)">
              <feather-icon icon="EyeIcon" class="mr-50" />
              <span>Stock Balance</span>
            </b-button>
          </template>
          <template #cell(defaultUomID)="data">
            {{ getUomName(data.item.defaultUomID) }}
          </template>

          <template #cell(purchasePrice)="data">
            Rs {{ data.item.purchasePrice.toFixed(2) }}
          </template>

          <template #cell(actions)="data">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="getRawMaterialView(data.item.id)">
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span>View</span>
              </b-dropdown-item>
              <b-dropdown-item :to="getRawMaterialEdit(data.item.id)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeRawMaterial(data.item.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="d-flex justify-content-center mb-1">
          <b-alert v-if="noResults" variant="warning" show>
            No raw materials found.
          </b-alert>
        </div>
        <div class="d-flex justify-content-center mb-1">
          <b-spinner
            variant="success"
            label="Spinning"
            block
            v-if="loading"
            style="width: 3rem; height: 3rem"
          ></b-spinner>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
        <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BAlert,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BAlert,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      rawmaterialData: [],
      productName: [],
      selectedName: null,
      selectedCategory: null,
      RawMaterialListing: [],
      categoryName: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      noResults: false,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        "name",
        "description",
        { key: "category.name", label: "Category" },
        { key: "defaultUomID", label: "Unit of Measure", sortable: true },
        { key: "lowStock", label: "Re-Order Level", sortable: true },
        { key: "purchasePrice", label: "Unit Price", sortable: true },
        { key: "balance", label: "View Balance" },
        "actions",
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("rawmaterialModule", {
      RawMaterialList: "rawmaterials",
      loading: "loading",
    }),
    ...mapGetters("itemUomModule", {
      itemuom: "itemuom",
      loading: "loading",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.RawMaterialList.length;
  },
  methods: {
    ...mapActions("itemUomModule", ["getItemUOMListAction"]),
    clearFilters() {
      this.selectedName = "";
      this.selectedCategory = "";

      this.RawMaterialListing = [...this.RawMaterialList];
      this.totalRows = this.leads.length;
      this.currentPage = 1;
    },
    orderFilterChanged() {
      this.RawMaterialListing = this.RawMaterialList;
      if (this.selectedName) {
        this.RawMaterialListing = this.RawMaterialListing.filter((obj) => {
          return obj.id === this.selectedName;
        });
      }
      if (this.selectedCategory) {
        this.RawMaterialListing = this.RawMaterialListing.filter((obj) => {
          return obj.category.id === this.selectedCategory;
        });
      }

      ///code for category filter here

      this.totalRows = this.RawMaterialListing.length;
      this.currentPage = 1;
    },
    getUomName(id) {
      let uom = this.itemuom.find((uom) => uom.id == id);
      if (uom) {
        return uom.name;
      }
      return "";
    },
    getUpdate(item) {
      return "/rawmaterials/stockTransaction/" + item;
    },
    getRawMaterialView(item) {
      return "/rawmaterials/view/" + item;
    },
    getRawMaterialEdit(item) {
      return "/rawmaterials/edit/" + item;
    },
    removeRawMaterial(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeRawMaterialAction(id)
            .then(() => {
              this.$swal({
                title: "Deleted!",
                text: "This raw material has been deleted.",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {
              this.$swal({
                title: "Error!",
                text: " Failed! " + error.response.data.message,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Your raw material is safe :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.noResults = filteredItems.length === 0;
    },
    ...mapActions("rawmaterialModule", [
      "getRawMaterialListAction",
      "removeRawMaterialAction",
    ]),
  },
  async mounted() {
    await this.getRawMaterialListAction()
      .then(() => {
        this.totalRows = this.RawMaterialList.length;
        this.RawMaterialListing = this.RawMaterialList;
        this.RawMaterialList.map((v, i) => {
          this.productName.push({ value: v.id, text: `${v.name}` });
          if (!this.categoryName.find((category) => category.value === v.category.id)) {
            this.categoryName.push({ value: v.category.id, text: `${v.category.name}` });
          }
        });
        // console.log(this.RawMaterialList)
      })
      .catch((response) => {
        console.log(response);
      });

    await this.getItemUOMListAction();
  },
};
</script>
