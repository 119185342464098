<template>
  <div>
    <!-- Table Container Card -->
    <b-card class="mb-0">
      <TableListProducts />
    </b-card>
  </div>
</template>

<script>
import TableListProducts from "./TableListProducts.vue";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTabs,
  BTab,
  BCardImg,
  BCardFooter,
} from "bootstrap-vue";
import vSelect from "vue-select";
// import { ref, onUnmounted } from '@vue/composition-api'
// import store from '@/store'
// import userStoreModule from '../apps/user/userStoreModule'

export default {
  components: {
    TableListProducts,
    BTabs,
    BTab,
    BCard,
    BCardImg,
    BCardFooter,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },

  // setup() {
  //   const USER_APP_STORE_MODULE_NAME = 'app-user'

  //   // Register module
  //   if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

  //   // UnRegister on leave
  //   onUnmounted(() => {
  //     if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  //   })

  //   return {

  //     // Sidebar
  //     isAddNewUserModalActive,

  //     fetchUsers,
  //     tableColumns,
  //     perPage,
  //     currentPage,
  //     totalUsers,
  //     dataMeta,
  //     perPageOptions,
  //     searchQuery,
  //     sortBy,
  //     isSortDirDesc,
  //     refUserListTable,
  //     refetchData,

  //     // Filter
  //     avatarText,

  //     // UI
  //     resolveUserownerVariant,
  //     resolveUserownerIcon,
  //     resolveUserStatusVariant,

  //     ownerOptions,
  //     planOptions,
  //     statusOptions,

  //     // Extra Filters
  //     ownerFilter,
  //     planFilter,
  //     statusFilter,
  //   }
  // },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
